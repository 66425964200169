<template>
    <div>
        <div ref="box"></div>
        <el-dialog title="管理图片" :visible.sync="dialogFormVisible" v-if="dialogFormVisible" custom-class="dialog"
                   width="740px">
            <FileUpload :chooseNum="10" :chooseFunc="fileChoose"></FileUpload>
        </el-dialog>
    </div>
</template>
<script>
  import E from "wangeditor"

  const {$, BtnMenu} = E
  import FileUpload from './FileUpload'

  export default {
    components: {
      FileUpload
    },
    props: {
      idx: Number,
      txt: String,
      callback: Function
    },
    data() {
      return {
        editor: '',
        content: this.txt,
        dialogFormVisible: false
      };
    },
    created() {
    },
    mounted() {
      var that = this
      this.editor = new E(this.$refs.box);
      this.editor.config.height = 200
      this.editor.config.zIndex = 2
      this.editor.config.showFullScreen = true
      this.editor.config.focus = false
      this.editor.config.placeholder = '请输入内容'
      this.editor.config.showLinkImg = false
      E.registerMenu('setimg', this.addMenu())
      this.editor.config.onchange = function (newHtml) {
        that.changeHtml(newHtml)
      }.bind(this)
      this.editor.config.menus = [          // 菜单配置
        'head',  // 标题
        'bold',  // 粗体
        'fontSize',  // 字号
        'fontName',  // 字体
        'italic',  // 斜体
        'underline',  // 下划线
        'strikeThrough',  // 删除线
        'foreColor',  // 文字颜色
        'backColor',  // 背景颜色
        //'link',  // 插入链接
        'list',  // 列表
        'justify',  // 对齐方式
        'quote',  // 引用
        'emoticon',  // 表情
        'image',  // 插入图片
        //'table',  // 表格
        //'code',  // 插入代码
        //'undo',  // 撤销
        //'redo'  // 重复
      ]
      this.editor.create();
      this.editor.txt.html(this.txt)
    },
    watch: {
      txt(newVal) {
        if (newVal && newVal != '<p><br/></p>') {
          if (newVal !== this.editor.txt.html()) {
            this.editor.config.focus = true
            this.editor.txt.html(newVal)
          }
        } else {
          this.editor.config.focus = false
          this.editor.txt.html('<p><br/></p>');
        }
      }
    },
    methods: {
      changeHtml(html) {
        this.callback(html,this.idx)
      },
      fileChoose(item) {
        var that = this
        this.dialogFormVisible = false
        item.forEach(function (child) {
          that.editor.cmd.do('insertHTML', '<img src="' + child.pic + '" style="max-width: 100%;" />')
        })
      },
      addMenu() {
        var that = this
        return class setImg extends BtnMenu {
          constructor(editor) {
            const $elem = $(
                `<div class="w-e-menu" data-title="图片">
                            <div class="w-e-up-btn">
                            <i class="w-e-icon-image"></i>
                            </div>
                        </div>`
            )
            super($elem, editor)
          }

          // 菜单点击事件
          clickHandler() {
            that.dialogFormVisible = true
          }

          tryChangeActive() {
          }
        }
      }
    },
  };
</script>
<style>
</style>
